import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'
import { ATTRIBUTE_TYPE_HELP } from '@entities/attributes/lib/attribute-type-help'
import { FileCard } from '@entities/files'
import { type ApiObjectAttribute } from '@shared/api/types'
import { InfoTooltip } from '@shared/ui'

import { useSkuUpdateAttributes } from '../model'

const SkuAttribute: ReactFc<ApiObjectAttribute> = (attribute) => {
  // Сохранение
  const { updateAttributeFiles, isSubmitting, isRemoving } = useSkuUpdateAttributes()

  const isFile = attribute.files

  return (
    <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
      <EuiFlexItem grow={3}>
        <InfoTooltip
          tooltip={ATTRIBUTE_TYPE_HELP[attribute.attribute_type!].TOOLTIP}
          color="GrayText"
        >
          {attribute.name}
        </InfoTooltip>
      </EuiFlexItem>

      <EuiFlexItem grow={7}>
        <AttributeFormField
          attribute={attribute}
          disablePlaceholder
          isCompressed
          isSubmitting={isSubmitting}
        />

        {isFile && attribute.files.length > 0 && (
          <>
            <EuiSpacer size="s" />
            <EuiFlexGroup gutterSize="s" wrap>
              {attribute.files.map((file) => {
                return (
                  <FileCard
                    key={file.id}
                    {...file}
                    onRemove={updateAttributeFiles({ attribute, file })}
                    isRemoving={isRemoving}
                  />
                )
              })}
            </EuiFlexGroup>
            <EuiSpacer />
          </>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export { SkuAttribute }
