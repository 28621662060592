import { convertSelectToPopoverOptions } from '@shared/lib'

import { attributeTypeOptions } from './attribute-type-options'
import { useAttributeGroupOptions } from './use-attribute-group-options'

const useAttributeFilterOptions = () => {
  const { groupOptions } = useAttributeGroupOptions()

  const filterTypeOptions = convertSelectToPopoverOptions(attributeTypeOptions)
  const filterGroupOptions = convertSelectToPopoverOptions(groupOptions)

  return { typeOptions: filterTypeOptions, groupOptions: filterGroupOptions }
}

export { useAttributeFilterOptions }
