import { attributeTypeOptions, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { type ApiAttribute } from '@shared/api/types'
import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { convertSelectToPopoverOptions } from '@shared/lib'
import { useParams } from 'react-router'

const useSearchFilterAttributes = () => {
  const { id: skuId } = useParams()
  const { data: sku } = useSkuApi(skuId)
  const { data: attributeGroups } = useAttributeGroupsList()
  const { hasParam, getParamValues, params: query } = useRouterParams()
  const attributeTypePopoverOptions = convertSelectToPopoverOptions(attributeTypeOptions)

  const doesAttributeMatchTypeFilters = (attribute: ApiAttribute) => {
    return hasParam(PARAMS.ATTRIBUTE_TYPE)
      ? getParamValues(PARAMS.ATTRIBUTE_TYPE).includes(attribute.attribute_type?.toString() || '')
      : true
  }

  const isNameMatchSearch = (attribute: ApiAttribute) => {
    const attributeName = attribute.name?.toLocaleLowerCase()
    const searchValue = query.search?.toLocaleLowerCase()

    return query.search ? attributeName.includes(searchValue) : true
  }

  const getNotEmptyAttributeGroups = (attributeGroups: AttributeGroup[]) => {
    return attributeGroups.filter((group) => {
      const hasAttributes = Boolean(
        sku?.attributes.find((attribute) => {
          return (
            attribute.object_group === group.id &&
            isNameMatchSearch(attribute) &&
            doesAttributeMatchTypeFilters(attribute)
          )
        }),
      )

      return hasAttributes
    })
  }

  const notEmptyAttributeGroups = getNotEmptyAttributeGroups(attributeGroups)

  return {
    isNameMatchSearch,
    attributeTypePopoverOptions,
    doesAttributeMatchTypeFilters,
    notEmptyAttributeGroups,
  }
}

export { useSearchFilterAttributes }
