import {
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiHorizontalRule,
  EuiSkeletonLoading,
} from '@elastic/eui'
import { useAttributeGroupsList } from '@entities/attributes'
import { SkuTabSkeleton, useSkuApi } from '@entities/catalog'
import {
  SkuAttributeGroups,
  useSearchFilterAttributes,
  useSkuUpdateAttributes,
} from '@features/catalog'
import { ACTION, LABEL, MESSAGE, PARAMS } from '@shared/config'
import { renderMultiple } from '@shared/lib'
import { FilterPopover, Search } from '@shared/ui'
import { SaveButton } from '@shared/ui/form'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router'

const SkuAttributesTab: ReactFc = () => {
  const { id: skuIdParam = '' } = useParams()
  const { isLoading: isSkuLoading } = useSkuApi(skuIdParam)
  const { isLoading: isAttributesGroupsLoading } = useAttributeGroupsList()

  const isLoading = isSkuLoading || isAttributesGroupsLoading

  // Фильтры и поиск
  const { attributeTypePopoverOptions, notEmptyAttributeGroups } = useSearchFilterAttributes()

  const isEmprtyAttributeGroups = notEmptyAttributeGroups.length === 0

  // Сохранение
  const { methods, changed, updateSkuHandler, isSubmitting } = useSkuUpdateAttributes()

  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      contentAriaLabel={LABEL.ATTRIBUTES}
      loadingContent={<>{renderMultiple(SkuTabSkeleton, 5)}</>}
      loadedContent={
        <>
          <EuiFlexGroup gutterSize="m">
            <EuiFlexItem grow={false}>
              <Search searchParam="search" placeholder={ACTION.FIND_ATTRIBUTE} />
            </EuiFlexItem>

            <EuiFilterGroup compressed>
              <FilterPopover
                label={LABEL.ATTRIBUTE_TYPE}
                param={PARAMS.ATTRIBUTE_TYPE}
                options={attributeTypePopoverOptions}
                searchable={false}
              />
            </EuiFilterGroup>

            <EuiFlexItem>
              <EuiFlexGroup justifyContent="flexEnd">
                <SaveButton
                  isActive={changed.length > 0}
                  onClick={updateSkuHandler}
                  isLoading={isSubmitting}
                />
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiHorizontalRule margin="s" />

          {!isLoading && isEmprtyAttributeGroups && MESSAGE.EMPTY}

          <FormProvider {...methods}>
            <EuiForm component="form" onSubmit={updateSkuHandler}>
              <SkuAttributeGroups isLoading={isLoading} />
            </EuiForm>
          </FormProvider>
        </>
      }
    />
  )
}

export { SkuAttributesTab }
